/* Mixin */
@import "../mixin.scss";

/* Variables */
@import "../variables";

/* Form reset */
@import "../reset";

/* Layouts */
@import "layout/header";
@import "layout/footer";

/* Bases*/
@import "base/alerts.scss";

/* Pages */
@import "pages/cart";
@import "pages/product";
@import "pages/printMethod";
@import "pages/account";
@import "pages/categories";
@import "pages/login";
@import "pages/company";

/* Modals */
@import "modals/deleteAccount";
@import "modals/custom";
@import "modals/select";
@import "modals/addCart";
@import "modals/login";
@import "modals/devis";

.separate {
   display: inline-block;
   margin-left: 5px;
   margin-right: 5px;
}

#page {
   padding-top: 60px;
   padding-bottom: 60px;
   header {
      margin-bottom: 30px;
      .title {
         font-size: 32px;
      }
   }
   section {
      img {
         width: 100%;
         margin-bottom: 30px;
      }
      h1, h2, h3, h4, h5 {
         margin-bottom: 30px;
      }
      ul {
         padding-left: 20px;
         li {
            list-style: initial;
         }
      }
   }
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23000\'%3E%3Cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3E%3C/svg%3E') 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}


#blog {
	padding-top: 120px;
	padding-bottom: 120px;
	header {
		div.title {
			font-size: 44px;
			font-weight: bold;
			margin-bottom: 60px;
		}
	}
	section {
		a.post {
			display: block;
			color: inherit;
			text-decoration: none;
			&:hover {
				color: inherit;
				text-decoration: underline;
			}
		}
		div.item {
			div.media {
				@include border-radius(4px);
				background-color: #f1f1f1;
				height: 200px;
				margin-bottom: 20px;
			}
			div.title {
				font-size: 14px;
			}
		}
	}
}

#categories_details {
   padding-bottom: 120px;
   img {
      width: 100%;
   }
}

#blog_view {
	padding-top: 120px;
	padding-bottom: 120px;
	header {
		div.title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 60px;
		}
	}
	section {
	}
}

div.popover {
	div.popover-body {
		padding: 20px;
		div.title {
			font-weight: bold;
		}
		div.description {
		}
		a {
			color: $brand-500;
		}
	}
}

/******/
.icon {
   display: inline-block;
   width: 48px;
   height: 48px;
   background-image: url('../../images/icons.png');
   transform: scale(0.5);
   &.icon-white {
      background-image: url('../../images/icons-white.png');
   }
   &.icon-small {
      transform: scale(0.4);
   }
}

$icons:
   "heart" 0,
   "user" 1,
   "cart" 2,
   "cart-add" 3,
   "file" 4,
   "trash" 5,
   "mark" 6,
   "fly" 7,
   "letter" 8,
   "upload" 9,
   "card" 10,
   "scan" 11,
   "search" 12;

@each $name, $index in $icons {
  .icon-#{$name} {
    background-position: -#{$index*48}px 0px;
  }
}


body {
   background-color: white;
   font-family: 'Noto Sans HK', sans-serif;
   -webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
 	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: 100%;
 	-ms-font-smoothing: 100%;
   padding-top: 80px;
}

.modal-backdrop {
   background-color: rgb(33,37,41);
   &.show {
      opacity: 0.2;
   }
}




section#slider {
   background-color: #F7F9FB;
	background-image: url('../../images/slide.jpg');
	background-size: cover;
	//background-position: 0px -100px;
   @include media(xs) {
      background-position: 0px 0px;
      height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
   }
   div.contain {
      background-color: white;
      margin-right: 50px;
      margin-top: 120px;
      margin-bottom: 220px;
      padding: 60px 50px;
      @include media(xs) {
         margin-top: 60px;
         padding: 30px 20px;
         margin-right: 30px;
         margin-left: 30px;
         text-align: center;
      }
      h1 {
         font-size: 40px;
         font-weight: bold;
         line-height: 1.15;
         margin-bottom: 34px;
         @include media(xs) {
            font-size: 24px;
            text-align: center;
         }
      }
   }
}

section#home-secteurs {
   padding-top: 65px;
   header {
      h2 {
         font-size: 22px;
         font-weight: bold;
         line-height: normal;
         margin-bottom: 8px;
      }
      p {
         font-size: 14px;
      }
   }
   section {
      padding-bottom: 65px;
      border-bottom: 1px solid #f1f1f1;
      div.card {
         border: none;
         div.media {
            @include border-radius(8px);
            margin-top: 50px;
            margin-bottom: 15px;
            background-color: #F7F9FB;
            height: 200px;
				img {
					@include border-radius(8px);
					width: 100%;
				}
         }
         h3 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;
            line-height: normal;
         }
      }
   }
}

section#home-categories {
   padding-top: 65px;
   padding-bottom: 65px;
   h2 {
      font-size: 22px;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 8px;
   }
   p {
      font-size: 14px;
   }
   div.card {
      border: none;
      div.media {
         @include border-radius(8px);
         margin-top: 50px;
         margin-bottom: 15px;
         background-color: #F7F9FB;
         height: 350px;
			img {
				@include border-radius(8px);
				width: 100%;
			}
      }
      h3 {
         font-size: 16px;
         font-weight: bold;
         margin-bottom: 8px;
         line-height: normal;
      }
      p {
         font-size: 14px;
         font-weight: normal;
         line-height: normal;
         color: #868e96;
      }
   }
}

section#callToAction {
   padding-top: 25px;
   padding-bottom: 65px;
   div.contain {
      @include border-radius(7px);
      background-color: white;
      box-sizing: border-box;
      height: 140px;
      box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.04);
      border: solid 1px #f8f8f8;
      padding: 40px;
      @include media(xs) {
         height: auto;
         padding: 26px;
      }
      h2 {
         font-size: 22px;
         font-weight: bold;
         letter-spacing: 0;
         line-height: 32px;
         margin-bottom: 8px;
      }
      p {
         font-size: 14px;
         letter-spacing: 0;
         line-height: 20px;
         color: #868E96;
      }
      a {
			 @include border-radius(3px);
			 position: absolute;
			 top: 40px;
			 right: 80px;
		 	 margin: 0px;
		    padding: 0px;
		    border: solid 1px black;
		    height: 44px;
		    line-height: 44px;
		    font-size: 11px;
		    font-weight: 500;
		    text-transform: uppercase;
		    background-color: black;
		    color: white;
		    letter-spacing: 0.6px;
		    padding-left: 25px;
		    padding-right: 25px;
			 display: inline-block;
          @include media(xs) {
             position: relative;
             top: 0px;
             left: 0px;
             right: auto;
             width: 100%;
             text-align: center;
          }
      }
   }
}

section#subFooter {
   background-color: #F7F9FB;
   padding-bottom: 110px;
   padding-top: 90px;
   div.item {
      padding-right: 60px;
      @include media(xs) {
         text-align: center;
         padding-right: 0px;
         padding-left: 30px;
         padding-right: 30px;
         margin-bottom: 30px;
      }
      div.illu {
         margin-bottom: 24px;
         img {
            width: 60px;
         }
      }
      h3 {
         @include no-mp();
         font-size: 14px;
         line-height: 20px;
         margin-bottom: 8px;
         font-weight: bold;
      }
      p {
         font-size: 14px;
         line-height: 20px;
         color: #868E96;
      }
   }
}

.modal-checkout-upload {
	.selector {
		ul {
			display: flex;
			align-items: center;
    		justify-content: center;
			border: 1px solid #e6e9e8;
			@include border-radius(4px);
			padding: 4px;
			margin-bottom: 12px;
			li {
				@include border-radius(4px);
				flex: 1;
				text-align: center;
				font-size: 12px;
				line-height: 32px;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
				&.active {
					color: white;
					background-color: black;
				}
			}
		}
	}
	.upload {
		.file {
			display: none;
			&.active {
				display: block;
			}
		}
		.text {
			display: none;
			&.active {
				display: block;
			}
		}
	}
}

#print-method_view {
   padding-bottom: 120px;
   .title {
      font-size: 32px;
      font-weight: bold;
      padding-top: 60px;
      padding-bottom: 60px;
   }
   img {
      width: 100%;
      margin-bottom: 30px;
   }
   h2 {
      font-size: 20px;
      margin-bottom: 12px;
   }
   p {
      margin-bottom: 32px;
   }
   ul {
      margin-bottom: 32px;
      padding: 18px;
      li {
         list-style: disc;
      }
   }
}
