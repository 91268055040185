.alert-notif {
   position: fixed;
   z-index: 999;
   bottom: 40px;
   right: 140px;
   @include no-mp();
   border-radius: 6px;

   &.alert-notif-left {
      left: 40px;
      right: auto;
   }

   &.slide-right {
      animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      &.alert-notif-left {
         animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
   }

   &.slide-out-right {
      animation: slide-out-right 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
   }

   @keyframes slide-right {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(100px);
      }
   }

   @keyframes slide-left {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(0px);
      }
   }

   @keyframes slide-out-right {
      0% {
        transform: translateX(140px);
        opacity: 1;
      }
      100% {
        transform: translateX(1000px);
        opacity: 0;
      }
   }

   .row {
      @include margin-h(16px);
      @include margin-v(12px);

      div.icon {
         @include circle(40px);

         span {
            font-size: 20px;
            color: #fff;
            position: relative;
            top: 10px;
            left: 10px;
         }
      }

      p {
         display: inline-block;
         @include no-mp();
         margin: auto;
         margin-left: 12px;
      }

      .icon-close-outline {
         font-size: 24px;
         margin-top: 8px;
         margin-bottom: 8px;
         color: #fff;
         cursor: pointer;
         margin-left: 20px;
      }
   }


   &.alert-success {
      background-color: $success-500;
      div.icon {
         background-color: $success-500;
      }
      p {
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 14px;
         letter-spacing: normal;
         color: var(--white);
      }
   }

   &.alert-error {
      background-color: $danger-500;
      div.icon {
         background-color: $danger-600;
      }
      p {
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 14px;
         letter-spacing: normal;
         color: var(--white);
      }
   }

   &.alert-information {
      background-color: $info-500;
      div.icon {
         background-color: $info-600;
      }
      p {
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 14px;
         letter-spacing: normal;
         color: var(--white);
      }
   }

   &.alert-warning {
      background-color: $warning-500;
      div.icon {
         background-color: $warning-600;
      }
      p {
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 14px;
         letter-spacing: normal;
         color: var(--white);
      }
   }
}
