div#devisModal {
   .close {
      color: black;
      opacity: 1;
   }
   div.modal-dialog {
      max-width: 620px;
   }
   div.modal-content {
      @include border-radius(3px);
      border: none;
      box-shadow: none;

      .row {
         margin-top: 10px;

         textarea {
            min-height: 80px;
            line-height: normal;
         }
      }
   }
   div.modal-footer {
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
      padding-top: 18px;
      display: block!important;
      border-top: 1px solid #eff1f6;
      .btn-default {
         float: right;
      }
   }
   div.modal-header {
      padding-left: 20px;
      padding-right: 20px;
      height: 65px;
      line-height: 65px;
      border-bottom: 1px solid #eff1f6;
     
   }
   div.modal-body {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
   }
  
}
