@mixin wrapperHeader() {
   div.header {
      @include border-radius(4px);
      position: relative;
      background-color: $black-100;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      padding-left: 20px;
      margin-bottom: 18px;
      line-height: 40px;
      @include media(xs) {
         height: auto;
         margin-top: 30px;
      }
      a {
         color: inherit;
      }
      ul.values {
         position: absolute;
         top: 0px;
         right: 0px;
         height: 40px;
         padding-right: 20px;
         font-size: 0px;
         @include media(xs) {
            position: relative;
            text-align: right;
         }
         li {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            width: 70px;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            @include media(xs) {
               font-size: 11px;
               width: 60px;
            }
         }
      }
   }
}

section#cart {
   background-color: $black-100;
	background-color: #f9fbff;
   padding-top: 70px;
   padding-bottom: 200px;
   @include media(xs) {
      background-color: white;
   }
	div.success {
		padding-top: 40px;
		padding-bottom: 40px;
		div.illu {
			@include circle(30px);
			display: inline-block;
			background-color: #27c397;
			color: white;
			text-align: center;
			line-height: 30px;
		}
		div.title {
			margin-top: 20px;
			font-size: 18px;
			font-weight: bold;
		}
		div.number {

		}
		div.paragraph {
			padding: 20px;
			p {
				@include no-mp();
				font-size: 14px;
			}
		}
		a {
			margin-top: 20px;
		}
	}
   div.empty {
      text-align: center;
      padding-top: 100px;
      padding-bottom: 100px;
      .icon {}
      strong {
         display: block;
         margin-bottom: 30px;
      }
   }
   div.steps {
      @include border-radius(4px);
      background-color: black;
      padding: 38px 25px;
      color: white;
      div.step {
         margin-bottom: 30px;
         &:last-child {
            margin-bottom: 20px;
         }
         div.name {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            padding-top: 16px;
            padding-bottom: 16px;
				padding-left: 10px;
				a {
					display: inline-block;
					color: inherit;
				}
            div.illu {
               @include cube(20px);
               position: relative;
               margin-right: 14px;
               display: inline-block;
               overflow: hidden;
               vertical-align: top;
               div.icon {
                  margin-top: -14px;
                  margin-left: -14px;
               }
            }
         }
         div.content {
				div.title {
					padding-left: 10px;
					padding-right: 10px;
					font-size: 12px;
					font-weight: bold;
					margin-top: 15px;
					margin-bottom: 10px;
				}
				div.address {
					padding-left: 10px;
					padding-right: 10px;
					font-size: 12px;
					margin-bottom: 20px;
				}
            ul {
               li {
                  position: relative;
                  width: 100%;
                  height: 18px;
                  margin-top: 12px;
                  div.field {
                     padding-left: 10px;
                     font-size: 12px;
                     position: absolute;
                     top: 0px;
                     left: 0px;
                     width: 70%;
                     height: 18px;
                  }
                  div.value {
                     padding-right: 10px;
                     font-size: 12px;
                     position: absolute;
                     top: 0px;
                     right: 0px;
                     width: 30%;
                     height: 18px;
                     text-align: right;
                  }
               }
            }
         }
      }
   }
   div.actions {
      padding-top: 15px;
      text-align: right;
      a {
         margin-left: 20px;
      }
   }
   div.wrapper {
      @include border-radius(4px);
      padding: 26px;
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      margin-bottom: 30px;
      @include media(xs) {
         padding: 0px;
      }
      div.more {
         div.summary {
            @include border-radius(4px);
            background-color: $black-100;
            padding: 30px;
            margin-bottom: 30px;
            ul {
               text-align: right;
               margin-bottom: 8px;
               li {
                  display: inline-block;
               }
               li.label {
                  color: $black-300;
                  font-size: 12px;
                  font-weight: bold;
               }
               li.value {
                  color: $black-300;
                  font-size: 12px;
                  width: 110px;
               }
               &:last-child {
                  margin-top: 20px;
                  li {
                     font-size: 14px;
                     font-weight: 900;
                     color: black;
                     text-transform: uppercase;
                  }
               }
            }
         }
      }
      div.account {
         .form {
            margin-bottom: 20px;
            @include grid-padding(12px);
				button {
					margin-top: 20px;
				}
         }
      }
      div.credit_card {
         @include wrapperHeader();
         div.options {
            padding-bottom: 20px;
            div.option {
               @include border-radius(4px);
					position: relative;
               border: solid 1px #cdcdcd;
               padding: 18px 30px;
               margin-bottom: 10px;
               opacity: 0.3;
               cursor: pointer;
               &.selected {
                  opacity: 1;
               }
					div.illu {
						display: inline-block;
                  width: 45px;
						img {
							position: relative;
							top: -10px;
							width: 18px;
						}
					}
               div.name {
                  display: inline-block;
                  width: 160px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
               div.description {
                  display: inline-block;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
					div.link {
						padding: 18px;
						position: absolute;
						top: 0px;
						right: 0px;
						height: 100%;
					}
            }
         }
      }
      div.payment_methods {
         @include wrapperHeader();
         div.options {
            padding-bottom: 20px;
            div.option {
               @include border-radius(4px);
					position: relative;
               border: solid 1px #cdcdcd;
               padding: 18px 30px;
               margin-bottom: 10px;
               opacity: 0.3;
               cursor: pointer;
               &.selected {
                  opacity: 1;
               }
					div.illu {
						display: inline-block;
                  width: 45px;
						img {
							position: relative;
							top: -10px;
							width: 18px;
						}
					}
               div.name {
                  display: inline-block;
                  width: 160px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
               div.description {
                  display: inline-block;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
					div.link {
						padding: 18px;
						position: absolute;
						top: 0px;
						right: 0px;
						height: 100%;
					}
            }
         }
      }
      div.delay {
         @include wrapperHeader();
         div.options {
            padding-bottom: 20px;
            div.option {
               @include border-radius(4px);
               border: solid 1px #cdcdcd;
               padding: 18px 30px;
               margin-bottom: 10px;
               opacity: 0.3;
               cursor: pointer;
					position: relative;
					&.selected {
                  opacity: 1;
						div.checked {
							display: inline-block;
						}
               }
					div.illu {
						display: inline-block;
                  width: 45px;
						img {
							position: relative;
							top: -10px;
							width: 18px;
						}
					}
               div.name {
                  display: inline-block;
                  width: 220px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
							&.emergency {
								color: #c93030;
							}
							&.fast {
								color: #e09229;
							}
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
               div.description {
                  display: inline-block;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
					div.checked {
						position: absolute;
						display: none;
						top: 20px;
						right: 20px;
						@include border-radius(18px);
						height: 36px;
						background-color: #27b893;
						color: white;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						line-height: 36px;
					}
            }
         }
      }
      div.account {
         @include wrapperHeader();
      }
      div.delivery {
         @include wrapperHeader();
         div.options {
            padding-bottom: 20px;
            div.option {
               @include border-radius(4px);
               border: solid 1px #cdcdcd;
               padding: 18px 30px;
               margin-bottom: 10px;
               opacity: 0.3;
					position: relative;
               cursor: pointer;
               &.selected {
                  opacity: 1;
						div.checked {
							display: inline-block;
						}
               }
					div.illu {
						display: inline-block;
                  width: 45px;
						img {
							position: relative;
							top: -10px;
							width: 18px;
						}
					}
               div.name {
                  display: inline-block;
                  width: 220px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
               div.description {
                  display: inline-block;
						position: relative;
						top: -12px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: bold;
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
               }
					div.checked {
						position: absolute;
						display: none;
						top: 20px;
						right: 20px;
						@include border-radius(18px);
						height: 36px;
						background-color: #27b893;
						color: white;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						line-height: 36px;
					}
            }
         }
      }
      div.products {
         div.item {
            margin-bottom: 50px;
            @include wrapperHeader();
            div.content {
               position: relative;
               padding-left: 120px;
               @include media(xs) {
                  padding-left: 80px;
               }
               div.media {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width: 100px;
                  height: 130px;
                  background-color: $black-100;
                  @include media(xs) {
                     width: 80px;
                     height: 80px;
                  }
						img {
							width: 100%;
						}
               }
               div.quantity {
                  div.line {
                     position: relative;
                     height: 54px;
                     padding-top: 14px;
                     padding-left: 45px;
                     border-bottom: solid 1px #eff1f6;
                     @include media(xs) {
                        padding-top: 0px;
                        padding-left: 0px;
                        height: auto;
                        text-align: right;
                     }
                     img {
                        border-radius: 3px;
                        width: 54px;
                        position: absolute;
                        top: 0px;
                        left: -80px;
                        @include media(xs) {
                           left: -65px;
                        }
                     }
                     div.color {
                        @include cube(54px);
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        padding-left: 7px;
                        padding-top: 18px;
                        @include media(xs) {
                           padding-top: 0px;
                           padding-left: 0px;
                           @include cube(26px);
                           position: relative;
                           display: inline-block;
                        }
                        span {
                           @include circle(19px);
                           display: block;
                        }
                     }
                     ul.attributes {
                        font-size: 0px;
                        @include media(xs) {
                           top: -14px;
                           left: 8px;
                           position: relative;
                           display: inline-block;
                           margin-right: 30px;
                        }
                        li {
                           &.name {
                              margin-left: -16px;
                              background: none;
                           }
                           @include border-radius(4px);
                           height: 26px;
                           background-color: $black-100;
                           font-size: 10px;
                           font-weight: bold;
                           line-height: 26px;
                           padding-left: 12px;
                           padding-right: 12px;
                           display: inline-block;
                           margin-right: 10px;
                        }
                     }
                     ul.values {
                        font-size: 0px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 26px;
                        padding-top: 14px;
                        padding-right: 20px;
                        @include media(xs) {
                           padding-top: 0px;
                           position: relative;
                           text-align: right;
                        }
                        li {
                           display: inline-block;
                           height: 26px;
                           width: 70px;
                           color: black;
                           font-size: 12px;
                           font-weight: bold;
                           text-align: center;
                           @include media(xs) {
                              font-size: 11px;
                              width: 60px;
                           }
                        }
                     }
                  }
               }
               div.configuration {
                  @include media(xs) {
                     margin-left: 0px;
                  }
                  div.upload {
                     @include border-radius(6px);
                     padding: 10px;
                     border: 2px dashed black;
                     margin-bottom: 12px;
                     position: relative;
                     .state {
                        font-size: 12px;
                        display: inline-block;
                        .waiting {
                           @include border-radius(4px);
                           font-size: 10px;
                           font-weight: 700;
                           background-color: orange;
                           color: white;
                           padding-left: 6px;
                           padding-right: 6px;
                        }
                        .done {
                           @include border-radius(4px);
                           font-size: 10px;
                           font-weight: 700;
                           background-color: #8fb063;
                           color: white;
                           padding-left: 6px;
                           padding-right: 6px;
                        }
                     }
                     .features {
                        ul {
                           li {
                              @include border-radius(4px);
                              display: inline-block;
                              background-color: $black-100;
                              color: black;
                              font-size: 10px;
                              padding-left: 6px;
                              padding-right: 6px;
                              padding-top: 2px;
                              padding-bottom: 2px;
                              font-weight: 700;
                           }
                        }
                     }
                     button {
                        border: none;
                        top: 8px;
                        right: 24px;
                        position: absolute;
                        height: 34px;
                        line-height: 34px;
                        text-transform: none;
                        background-color: black;
                        color: white;
                     }
                  }
						div.main {
							position: relative;
                     background-color: $black-100;
                     @include border-radius(4px);
                     font-size: 16px;
                     font-weight: bold;
                     padding-left: 20px;
                     margin-bottom: 18px;
                     padding-top: 14px;
                     padding-bottom: 18px;
                     margin-top: 22px;
                     .features {
                        ul {
                           li {
                              @include border-radius(4px);
                              display: inline-block;
                              background-color: black;
                              color: white;
                              font-size: 10px;
                              padding-left: 6px;
                              padding-right: 6px;
                              padding-top: 2px;
                              padding-bottom: 2px;
                           }
                        }
                     }
						}
                  div.line {
                     position: relative;
                     font-size: 12px;
                     font-weight: bold;
                     padding-left: 20px;
                     margin-bottom: 10px;
                  }
						div.sub {
							position: relative;
							margin-top: 10px;
							margin-bottom: 10px;
							ul {
								li {
									line-height: 20px;
								}
							}
						}
                  b {
                     @include no-mp();
                     font-size: 12px;
                     margin-bottom: 2px;
                     @include media(xs) {
                        font-size: 11px;
                     }
                  }
                  p {
                     @include no-mp();
                     font-size: 12px;
                     font-weight: 500;
                  }
                  ul.values {
                     position: absolute;
                     top: 0px;
                     right: 0px;
                     height: 100%;
                     padding-right: 20px;
                     font-size: 0px;
                     @include media(xs) {
                        padding-right: 20px;
                        text-align: right;
                     }
                     li {
                        display: inline-block;
                        height: 100%;
                        width: 70px;
                        font-size: 12px;
                        font-weight: bold;
                        text-align: center;
                        @include media(xs) {
                           width: 55px;
                           text-align: right;
                           font-size: 11px;
                        }
                     }
                  }
						div.selector {
							position: absolute;
							top: 20px;
							right: 20px;
							ul {
								@include border-radius(3px);
								background-color: white;
								padding: 5px;
								li {
									@include border-radius(3px);
									display: inline-block;
									font-size: 10px;
									padding-left: 15px;
									padding-right: 15px;
									height: 24px;
									line-height: 24px;
									cursor: pointer;
									&.active {
										background-color: black;
										color: white;
									}
								}
							}
						}
               }
               div.subAmount {
                  position: relative;
                  font-size: 14px;
                  font-weight: bold;
                  padding-left: 20px;
                  margin-bottom: 20px;
                  margin-top: 40px;
                  height: 20px;
                  b {
                     @include no-mp();
                     font-size: 14px;
                     font-weight: 900;
                     line-height: 20px;
                  }
                  ul.values {
                     position: absolute;
                     top: 0px;
                     right: 0px;
                     height: 20px;
                     padding-right: 20px;
                     font-size: 0px;
                     li {
                        display: inline-block;
                        height: 20px;
                        line-height: 20px;
                        width: 70px;
                        font-size: 14px;
                        font-weight: 900;
                        text-align: center;
                        @include media(xs) {
                           width: 55px;
                           text-align: right;
                           font-size: 12px;
                        }
                     }
                  }
               }
            }
            div.footer {
               padding-top: 20px;
               text-align: right;
               a {
               }
            }
         }
      }
   }
}

.modal-checkout-upload {
   .modal-dialog {
      border: none;
      .modal-content {
         border: none;
         .modal-header {
            border: none;
            .modal-title {
            }
         }
      }
   }
   input[type="file"] {
       display: none;
   }
   .customFile, .uniqueFile {
       @include no-mp();
       @include border-radius(3px);
       border: 1px solid black;
       display: inline-block;
       padding-left: 20px;
       padding-right: 20px;
       line-height: 28px;
       height: 28px;
       cursor: pointer;
       background-color: black;
       font-size: 10px;
       font-weight: 500;
       text-transform: uppercase;
       letter-spacing: 0.6px;
       color: white;
       margin-bottom: 10px;
       margin-top: 10px;
       i {
          margin-right: 10px;
       }
       a {
          margin-left: 10px;
          color: white;
          i {
             margin-right: 0px;
          }
       }
   }
   div.file {
      display: none;
      background-color: #f7f9fb!important;
      padding: 18px!important;
      border-radius: 4px;
      div.title {
         font-size: 12px;
         font-weight: bold;
      }
      div.paragraph {
         font-size: 12px;
      }
      &.active {
         display: block;
      }
   }
   div.text {
      display: none;
      div.title {
         font-size: 12px;
         font-weight: bold;
      }
      div.paragraph {
         font-size: 12px;
      }
      &.active {
         display: block;
      }
   }
   div.unique {
      div.wrapper {
         background-color: #f7f9fb!important;
         padding: 18px!important;
         margin-top: 10px;
         margin-bottom: 10px;
         padding: 18px 20px;
         margin-bottom: 20px;
         div.title {
            font-size: 12px;
            font-weight: bold;
         }
         div.paragraph {
            font-size: 12px;
         }
      }
   }
   textarea {
      min-height: 120px;
   }
   div.currentFile {
      font-size: 14px;
      position: relative;
      a {
         position: absolute;
         top: 5px;
         right: 10px;
      }
   }
   button[type="submit"] {
      width: 100%;
   }
}
