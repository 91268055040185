section#company_description,
section#company_recrutement,
section#company_values,
section#company_partnership,
section#contact_us
 {
	padding-top: 100px;
	padding-bottom: 100px;
	header {
		padding-bottom: 40px;
		margin-bottom: 32px;
	}
	section {
		p {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
}

section#contact_us {
	section {
		h2 {
			font-size: 22px;
		}
	  
		.informations {
			border-right: 1px solid #f3f3f3;
			padding-right: 40px;

			p {
				margin-bottom: 10px;
				margin-top: 10px;
			}
		}

		.form {
			padding-left: 40px;

			textarea {
				display: block;
				width: 100%;
				border-radius: 4px;
				border: solid 1px #e6e9e8;
				font-size: 12px;
				font-weight: 500;
				height: 130px;
			}

			button {
				margin-top: 20px;
				float: right;
			}
		}

    
   }
}
