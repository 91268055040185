// Responsive
@mixin media($media) {
  @if $media == smallphone {
    @media (max-width: 470px) { @content; }
  }
  @if $media == mediumphone {
    @media (min-width: 471px) and (max-width: 767px) { @content; }
  }
  @else if $media == mediumtablet {
    @media (min-width: 768px) and (max-width: 991px) { @content; }
  }
  @else if $media == smalldesktop {
    @media (min-width: 992px) and (max-width: 1199px) { @content; }
  }
  @else if $media == mediumdesktop {
    @media (min-width: 1200px) and (max-width: 1399px) { @content; }
  }
  @else if $media == largedesktop {
    @media (min-width: 1400px) and (max-width: 1599px) { @content; }
  }
  @else if $media == bigdesktop {
    @media (min-width: 1600px) and (max-width: 1999px) { @content; }
  }
  @else if $media == xbigdesktop {
    @media (min-width: 2000px) { @content; }
  }
  @else if $media == xs {
    @media (max-width: 767px) { @content; }
  }
  @else if $media == sm {
    @media (min-width: 768px) and (max-width: 991px) { @content; }
  }
  @else if $media == md {
    @media (min-width: 992px) and (max-width: 1199px) { @content; }
  }
  @else if $media == lg {
    @media (min-width: 1200px) and (max-width: 1599px) { @content; }
  }
  @else if $media == xlg {
    @media (min-width: 1600px) and (max-width: 1999px) { @content; }
  }
  @else if $media == xxlg {
    @media (min-width: 2000px) { @content; }
  }

}

@mixin break($min,$max){
    @media (min-width: $min) and (max-width: $max) { @content; }
}

@mixin phone(){
    @media (max-width: 470px) { @content; }
    @media (min-width: 471px) and (max-width: 767px) { @content; }
    @media (min-width: 768px) and (max-width: 991px) { @content; }
}

@mixin tablet(){
    @media (min-width: 768px) and (max-width: 991px) { @content; }
    @media (min-width: 992px) and (max-width: 1199px) { @content; }
}

@mixin tablet-landscape(){
    @media (min-width: 992px) and (max-width: 1199px) { @content; }
}

@mixin tablet-portrait(){
    @media (min-width: 768px) and (max-width: 991px) { @content; }
}

@mixin size($with, $height){
    width: $with;
    height: $height;
}


@mixin textarea($with,$height){
	min-height: $height;
	max-height: $height;
	height: $height;
	min-width: $with;
	max-width: $with;
	width: $with;
}

// Mixin color
@mixin bg-purple(){
	background-color: $purple;
}
@mixin bg-purple-light(){
	background-color: $purple-light;
}

@mixin filter-gray($scale){
   -webkit-filter: grayscale($scale);
	   -moz-filter: grayscale($scale);
	    -ms-filter: grayscale($scale);
         -o-filter: grayscale($scale);
            filter: grayscale($scale);
}

// Transition
@mixin transition($time){
	-webkit-transition: all #{$time}s ease;
	   -moz-transition: all #{$time}s ease;
	    -ms-transition: all #{$time}s ease;
         -o-transition: all #{$time}s ease;
            transition: all #{$time}s ease;
}
@mixin transition-delay($time){
	-webkit-transition-delay: #{$time}s ease;
	   -moz-transition-delay: #{$time}s ease;
	    -ms-transition-delay: #{$time}s ease;
         -o-transition-delay: #{$time}s ease;
            transition-delay: #{$time}s ease;
}
@mixin transitionScale($scale){
	-webkit-transform: scale($scale);
	   -moz-transform: scale($scale);
	    -ms-transform: scale($scale);
         -o-transform: scale($scale);
            transform: scale($scale);
}

// Blur
@mixin blur($blur){
    -webkit-filter: blur($blur);
    filter: blur($blur);
}

// Transform
@mixin transform-scale($scale){
    -webkit-transform: scale($scale);
    -moz-transform: scale($scale);
    -ms-transform: scale($scale);
    -o-transform: scale($scale);
    transform: scale($scale);
}

// Border
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

@mixin border-radius-top-left($radius) {
	-webkit-border-top-left-radius: $radius;
       -moz-border-top-left-radius: $radius;
        -ms-border-top-left-radius: $radius;
            border-top-left-radius: $radius;
}

@mixin border-radius-bottom-left($radius) {
	-webkit-border-bottom-left-radius: $radius;
       -moz-border-bottom-left-radius: $radius;
        -ms-border-bottom-left-radius: $radius;
            border-bottom-left-radius: $radius;
}

@mixin border-radius-top-right($radius) {
	-webkit-border-top-right-radius: $radius;
       -moz-border-top-right-radius: $radius;
        -ms-border-top-right-radius: $radius;
            border-top-right-radius: $radius;
}

@mixin border-radius-bottom-right($radius) {
	-webkit-border-bottom-right-radius: $radius;
       -moz-border-bottom-right-radius: $radius;
        -ms-border-bottom-right-radius: $radius;
            border-bottom-right-radius: $radius;
}

@mixin border-radius-top($radius) {
    @include border-radius-top-left($radius);
    @include border-radius-top-right($radius);
}

@mixin border-radius-bottom($radius) {
    @include border-radius-bottom-left($radius);
    @include border-radius-bottom-right($radius);
}

@mixin border-radius-left($radius) {
    @include border-radius-top-left($radius);
    @include border-radius-bottom-left($radius);
}

@mixin border-radius-right($radius) {
    @include border-radius-top-right($radius);
    @include border-radius-bottom-right($radius);
}


@mixin rotate($deg){
    -webkit-transform: rotate(#{$deg}deg); /* Chrome, Safari, Opera */
		-ms-transform: rotate(#{$deg}deg); /* IE 9 */
    		transform: rotate(#{$deg}deg);
}

@mixin rotation($deg){
    -ms-transform: rotate($deg); /* IE 9 */
    -webkit-transform: rotate($deg); /* Chrome, Safari, Opera */
    transform: rotate($deg);
    transform-origin: initial;
}

@mixin border-light($color){
	border: 1px solid $color;
}

// Box & text shadow
@mixin box-shadow($params){
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

// Size
@mixin cube($size) {
	width: $size;
	height: $size;
}
@mixin circle($size) {
	width: $size;
	height: $size;
	@include border-radius($size/2);
}

@mixin absolute($left,$top){
	position: absolute;
	left:$left;
	top:$top;
}

@mixin relative($left,$top){
	position: relative;
	left:$left;
	top:$top;
}

@mixin relative-right($right,$top){
	position: relative;
	right:$right;
	top:$top;
}

@mixin fixed($left,$top){
	position: fixed;
	left:$left;
	top:$top;
}

@mixin fixed-right($right,$top){
	position: fixed;
	right:$right;
	top:$top;
}

@mixin absolute-bottom($left,$bottom){
	position: absolute;
	left:$left;
	bottom:$bottom;
}

@mixin absolute-right($right,$top){
	position: absolute;
	right:$right;
	top:$top;
}

@mixin absolute-right-bottom($right,$bottom){
	position: absolute;
	right:$right;
	bottom:$bottom;
}

@mixin no-mp(){
	margin: 0px;
	padding: 0px;
}

@mixin fullsize(){
    width: 100%;
    height: 100%;
}

@mixin block-height($height){
    height: $height;
    min-height: $height;
    max-height: $height;
}

@mixin block-width($width){
    width: $width;
    min-width: $width;
    max-width: $width;
}

@mixin margin-h($margin){
  margin-left: $margin;
  margin-right: $margin;
}

@mixin margin-v($margin){
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin padding-h($padding){
    padding-left: $padding;
    padding-right: $padding;
}

@mixin padding-v($padding){
    padding-top: $padding;
    padding-bottom: $padding;
}

@mixin background-initial(){
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
}

@mixin grid-padding($padding){
    .row {
        margin-left: -$padding!important;
        margin-right: -$padding!important;
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-left: $padding!important;
        padding-right: $padding!important;
    }
}

@mixin placeholder(){
   &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
   }
   &::-moz-placeholder { /* Firefox 19+ */
    @content;
   }
   &:-ms-input-placeholder { /* IE 10+ */
    @content;
   }
   &:-moz-placeholder { /* Firefox 18- */
    @content;
   }
}

@mixin container($width){
    .container {
    	width: $width;
    	@include phone() {
    		padding-left: 30px;
    		padding-right: 30px;
    	}
    	@include media(smallphone) {
    		width: 100%;
    	}
    	@include media(mediumphone) {
    		width: 100%;
    	}
    	@include tablet {
    		width: auto;
    	}
    	@include media(smalldesktop) {
    		width: auto;
    	}
    	@include media(mediumdesktop) {
    		width: $width;
    	}
    	@include media(largedesktop) {
    		width: $width;
    	}
    	@include media(bigdesktop) {
    		width: $width;
    	}
    }
}
