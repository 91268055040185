footer#footer {
   background-color: black;
   color: white;
   padding-top: 140px;
   padding-bottom: 140px;
   p {
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
   }
   h2 {
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1px;
      color: white;
      margin-bottom: 16px;
      text-transform: uppercase;
   }
   ul {
      margin-bottom: 45px;
      li {
         a {
            font-size: 12px;
            font-weight: normal;
            line-height: 2;
            color: white;
         }
      }
   }
   ul.social {
      li {
         display: inline-block;
         margin-right: 6px;
         a {
            font-size: 16px;
         }
      }
   }
   div.group-input {
      @include border-radius(3px);
      width: 100%;
      height: 52px;
      border: solid 1px #f9f9f9;
      background-color: #2e3338;
      position: relative;
      margin-top: 10px;
      margin-bottom: 40px;
      input {
         margin-top: -1px;
         height: 50px;
         background: none;
         border: none;
         padding-left: 20px;
         line-height: 50px;
         color: white;
         font-size: 12px;
         &:focus {
            outline: none;
            box-shadow: none;
         }
         @include placeholder() {
            font-size: 12px;
            line-height: 50px;
         }
      }
      button {
         @include cube(50px);
         position: absolute;
         background: none;
         border: none;
         top: 0px;
         right: 14px;
         text-align: center;
         color: white;
         font-size: 12px;
         font-weight: 900;
         text-transform: uppercase;
         cursor: pointer;
      }
   }
}
