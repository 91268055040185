div#loginModal {
	div.modal-dialog {
		border: none;
		max-width: 380px;
		div.modal-content {
			border: none;
			div.modal-header {
				padding: 30px;
				border: none;
			}
			div.modal-body {
				padding: 30px;
				button {
					margin-top: 40px;
					width: 100%;
				}
				input {
					width: 100%;
				}
				a.forgot-password {
					color: $black-300;
					font-size: 12px;
				}
			}
		}
	}
	div.modal-footer {

	}
}
