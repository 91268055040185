ul {
   @include no-mp();
   li {
      list-style: none;
   }
}

a {
   color: inherit;
}


@media (min-width: 1200px) {
   .container {
       max-width: 1170px;
   }
}

.text-right {
   text-align: right;
}

label {
   font-size: 12px;
   font-weight: bold;
   height: 17px;
   line-height: 17px;
   margin-bottom: 7px;
}

.form-control {
   @include border-radius(4px);
   height: 40px;
   border: solid 1px #e6e9e8;
   font-size: 12px;
   font-weight: 500;
   line-height: 40px;
}

.group-checkbox {
	position: relative;
	margin-top: 10px;
   input {
		float: left;
	}
	label {
		position: relative;
		top: -8px;
		left: 10px;
	}
}

.btn {
   vertical-align: top;
   &.btn-default {
      @include no-mp();
      border: solid 1px black;
      height: 44px;
      line-height: 44px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: black;
      color: white;
      letter-spacing: 0.6px;
      padding-left: 25px;
      padding-right: 25px;
   }
   &.btn-primary {
      @include no-mp();
      border: solid 1px #e6e9e8;
      height: 44px;
      line-height: 44px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: white;
      color: black;
      letter-spacing: 0.6px;
      padding-left: 25px;
      padding-right: 25px;
   }
	&.btn-success {
      @include no-mp();
		@include transition(0.4);
      border: solid 1px #27c397;
      height: 44px;
      line-height: 44px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: #27c397;
      color: white;
      letter-spacing: 0.6px;
      padding-left: 25px;
      padding-right: 25px;
		&:hover {
			border: solid 1px #23ae87;
			background-color: #23ae87;
		}
   }
   .icon {
      position: relative;
      top: -3px;
      left: -10px;
      display: inline-block;
      vertical-align: top;
   }
   &.btn-small {
      height: 36px;
      line-height: 36px;
      padding-left: 15px;
      padding-right: 15px;
      .icon {
         left: -10px;
         top: -6px;
         margin-right: -10px;
      }
   }
   &.btn-stretch {
      width: 100%;
   }
}

.mt-1 {
   margin-top: 12px;
}

.mt-2 {
   margin-top: 24px;
}
