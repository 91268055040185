
section#reset {
   padding-top: 100px;
   padding-bottom: 200px;    
   .wrapper {
  
      border-radius: 3px;
      padding: 50px;
      border: 1px solid $black-200;
      h1 {
         font-size: 22px;
         font-weight: bold;
         letter-spacing: 0;
         line-height: 32px;
       
      }
      h2 {
         font-size: 14px;
         letter-spacing: 0;
         line-height: 20px;
         color: $black-300;
      }

      div.form-group {

      }

      button {

      }
   }
}
