header#header {
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 80px;
   z-index: 99;
   background-color: white;
   border-bottom: 1px solid $black-200;
	.logo {
		margin-top: 16px;
		margin-left: 20px;
		width: 140px;
	}
   ul.nav {
      height: 80px;
      @include media(xs) {
         display: none;
      }
      li {
         display: inline-block;
         height: 80px;
         a {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: bold;
            color: black;
            line-height: 80px;
            padding-left: 20px;
            padding-right: 20px;
         }
         .dropdown-item {
            line-height: 20px;
         }
         ul {
            li {
               height: auto;
            }
         }
      }
   }
   ul.actions {
      @include media(xs) {
         text-align: right;
      }
      li.item {
         display: inline-block;
			position: relative;
			padding-top: 10px;
         &.handle-nav {
            display: none;
            @include media(xs) {
               display: inline-block;
               margin-left: 6px;
            }
            position: relative;
            top: -8px;
            left: 6px;
            ul {
               @include no-mp();
               li {
                  width: 22px;
                  height: 3px;
                  background-color: black;
                  margin-bottom: 5px;
               }
            }
         }
         &.account {
            a {
               position: relative;
               top: -12px;
               display: inline-block;
               line-height: 24px;
               padding-left: 40px;
               span {
                  position: absolute;
                  top: -14px;
                  left: -4px;
               }
            }
         }
         &.cart {

         }
         &.favorite {
            span {
               position: relative;
               top: 3px;
            }
         }
         &.favorite, &.account {
            @include media(xs) {
               display: none;
            }
         }
         .icon {
         }
			span.notification {
				@include circle(14px);
				background-color: red;
				color: white;
				position: absolute;
				right: 6px;
				bottom: 12px;
				line-height: 14px;
				text-align: center;
				font-weight: bold;
				font-size: 9px;
			}
      }
   }
}

nav#nav-mobile {
   @include transition(0.4);
   z-index: 1001;
   position: fixed;
   top: 0px;
   right: -280px;
   width: 280px;
   height: 100%;
   background-color: white;
   padding: 30px;
   overflow: scroll;
   .account {
      @include border-radius(5px);
      border: 1px solid black;
   }
   .title {
      font-weight: bold;
      margin-bottom: 12px;
   }
   .links {
      margin-bottom: 24px;
      li {
         a {
            color: inherit;
            font-size: 14px;
         }
      }
   }
   &.active {
      right: 0px;
   }
}

div#nav-mobile-overlay {
   z-index: 1000;
   background-color: rgba(0,0,0, 0.4);
   top: 0px;
   left: 0px;
   position: fixed;
   width: 100%;
   height: 100%;
   display: none;
   &.active {
      display: block;
   }
}
