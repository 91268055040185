section#categories {
   padding-top: 40px;
   padding-bottom: 60px;
   div.breadcrumb {
      @include border-radius(0px);
      height: 50px;
      border-top: 1px solid #f7f9fb;
      border-bottom: 1px solid #f7f9fb;
      background: none;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 50px;
   }
   h1 {
      @include no-mp();
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 12px;
   }
   p {
      font-size: 14px;
      font-weight: normal;
      color: #212529;
   }
   div.actions {
      text-align: right;
      margin-top: 22px;
      margin-bottom: 32px;
      div.orderBy {
         @include border-radius(5px);
         padding-left: 25px;
         padding-right: 25px;
         display: inline-block;
         background-color: #f7f9fb;
         height: 48px;
         line-height: 48px;
         font-size: 12px;
         font-weight: normal;
      }
   }
   div.paginate {
      text-align: center;
      ul {
         li {
            background-color: #f7f9fb;
            display: inline-block;
            font-size: 13px;
            font-weight: bold;
            width: 24px;
            height: 24px;
            line-height: 24px;
            @include border-radius(6px);
            &.active {
               background-color: black;
               color: white;
            }
         }
      }
   }
   div.items {
      a {
         display: block;
         color: inherit;
         &:hover {
            color: inherit;
            text-decoration: none;
         }
      }
      div.item {
         margin-bottom: 50px;
         div.media {
            background-color: #f7f9fb;
            height: 334px;
            border: 1px solid #f7f7f7;
            margin-bottom: 15px;
				overflow: hidden;
            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
         h3 {
            @include no-mp();
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
         }
         p {
            @include no-mp();
            font-size: 10px;
            font-weight: 500;
            color: #868e96;
            margin-bottom: 8px;
         }
         ul.colors {
            margin-bottom: 6px;
            li {
               @include circle(16px);
               border: 1px solid #ced4da;
               display: inline-block;
               position: relative;
               div {
                  @include circle(12px);
                  padding: 0px;
                  position: absolute;
                  overflow: hidden;
                  top: 50%;
                  left: 50%;
                  margin-left: -6px;
                  margin-top: -6px;
                  display: block;
                  line-height: 0px;
               }
               &.single {
                  span {
                     position: absolute;
                     top: 0px;
                     left: 0px;
                     margin: 0px;
                     padding: 0px;
                     display: inline-block;
                     width: 12px;
                     height: 12px;
                  }
               }
               &.multiple {
                  span {
                     position: absolute;
                     top: 0px;
                     left: 0px;
                     margin: 0px;
                     padding: 0px;
                     display: inline-block;
                     width: 6px;
                     height: 12px;
                     &.color-2 {
                        left: 6px;
                     }
                  }
               }
            }
         }
         div.price {
            font-size: 12px;
            font-weight: 500;
         }
      }
   }
   div.filters {
      @include border-radius(5px);
      background-color: #f7f9fb;
      padding: 36px;
      div.group {
         margin-bottom: 30px;
         div.title {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            line-height: normal;
            margin-bottom: 28px;
         }
         div.options {
            ul {
               li {
                  height: 20px;
                  margin-bottom: 15px;
                  font-size: 12px;
                  font-weight: 500;
                  a {
                     color: inherit;
                  }
               }
            }
         }
      }
   }
}
