div#addCartModal {
   .close {
      color: black;
      opacity: 1;
   }
   div.modal-dialog {
      max-width: 850px;
   }
   div.modal-content {
      @include border-radius(3px);
      border: none;
      box-shadow: none;
   }
   div.modal-footer {
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
      padding-top: 18px;
      display: block!important;
      border-top: 1px solid #eff1f6;
      @include media(xs) {
         height: auto;
      }
      .btn {
         @include media(xs) {
            width: 100%;
            margin-bottom: 6px;
         }
      }
      .btn-primary {
         float: left;
      }
      .btn-default {
         float: right;
      }
   }
   div.modal-header {
      padding-left: 20px;
      padding-right: 20px;
      height: 65px;
      line-height: 65px;
      border-bottom: 1px solid #eff1f6;
      h5 {
         font-size: 12px;
         font-weight: bold;
         line-height: 32px;
      }
   }
   div.modal-body {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
   }
   div.media {
      background-color: $black-100;
      height: 230px;
      @include media(xs) {
         margin-bottom: 12px;
      }
		img {
			width: 100%;
         @include media(xs) {
            height: 100%;
            object-fit: cover;
         }
		}
   }
   div.flash {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 18px;
   }
   div.title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
   }
   div.quantity {
      padding-bottom: 8px;
      div.line {
         position: relative;
         height: 52px;
         border-bottom: 1px solid #eff1f6;
         padding-left: 50px;
         &:last-child {
            border: none;
         }
         div.color {
            position: absolute;
            top: 0px;
            left: 0px;
            padding-top: 16px;
            padding-left: 14px;
            span {
               display: block;
               @include circle(19px);
            }
         }
         ul {
            font-size: 0px;
            padding-top: 13px;
            margin-left: -12px;
            li {
               &.name {
                  background: none;
                  width: 100px;
               }
               @include border-radius(4px);
               background-color: $black-100;
               height: 26px;
               padding-left: 12px;
               padding-right: 12px;
               display: inline-block;
               font-size: 10px;
               font-weight: bold;
               line-height: 26px;
               margin-right: 10px;
            }
         }
      }
   }
   div.print {
      div.line {
         padding-bottom: 24px;
         b {
            font-size: 12px;
         }
         ul {
            li {
               @include border-radius(4px);
               padding: 4px;
               padding-left: 8px;
               padding-right: 8px;
               color: white;
               font-size: 12px;
               background-color: black;
               line-height: 16px;
               display: inline-block;
            }
         }
      }
   }
   div.summary {
      @include border-radius(4px);
      position: relative;
      height: 40px;
      background-color: $black-100;
      font-size: 12px;
      font-weight: bold;
      padding-left: 20px;
      line-height: 40px;
      div.amount {
         position: absolute;
         top: 0px;
         right: 0px;
         padding-right: 20px;
         height: 40px;
      }
   }
}
