section#product {
   padding-top: 60px;
   padding-bottom: 200px;
   div.media {
      margin-bottom: 10px;
      @include media(xs) {
         height: auto;
         text-align: center;
      }
      img {
         border-radius: 6px;
         width: 100%;
         @include media(xs){
            width: 80%;
            margin: 0px auto;
         }
      }
   }
   div.thumbs {
      ul {
         li {
            width: 52px;
            display: inline-block;
            background-color: $black-100;
            margin-bottom: 8px;
            margin-right: 6px;
            img {
               border-radius: 4px;
               width: 100%;
            }
         }
      }
   }
   div.breadcrumb {
      @include no-mp();
      font-size: 12px;
      font-weight: normal;
      background: none;
      margin-bottom: 24px;
   }
   div.title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 4px;
   }
   div.reference {
      font-size: 12px;
      font-weight: normal;
      color: $black-300;
      margin-bottom: 12px;
   }
   div.links {
      margin-bottom: 16px;
		display: inline-block;
		margin-left: 0px;
      a {
         color: $brand-500;
         font-size: 12px;
         text-decoration: underline;
      }
   }
   div.short_description {
      color: $black-300;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 16px;
      h1 {
         font-size: 20px;
      }
      h2 {
         font-size: 18px;
      }
      h3 {
         font-size: 16px;
      }
      h4 {
         font-size: 14px;
      }
   }
   div.description {
      color: $black-300;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 16px;
      padding-top: 40px;
      h1 {
         font-size: 20px;
      }
      h2 {
         font-size: 18px;
      }
      h3 {
         font-size: 16px;
      }
      h4 {
         font-size: 14px;
      }
   }
   div.features {
      margin-bottom: 36px;
      ul {
         li {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            i {
               color: $success-500;
               margin-right: 6px;
            }
         }
      }
   }
   div.moduleColors {
      padding-bottom: 40px;
      .name {
         text-transform: uppercase;
         font-size: 12px;
         font-weight: bold;
         margin-bottom: 20px;
      }
      ul.colors {
         li {
            @include circle(30px);
            display: inline-block;
            margin-right: 6px;
            border: 1px solid #ced4da;
            padding: 4px;
            span {
               @include circle(20px);
               display: inline-block;
            }
         }
      }
   }
   div.moduleQuantity {
      padding-bottom: 50px;
      display: none;
      &.active {
         display: block;
      }
      .name {
         text-transform: uppercase;
         font-size: 12px;
         font-weight: bold;
         margin-bottom: 20px;
      }
      div.labels {
         @include border-radius(4px);
         background-color: $black-100;
         height: 40px;
         padding-left: 76px;
         position: relative;
         ul {
            font-size: 0;
            &.one-size {
               li {
                  width: 100px;
               }
            }
            li {
               height: 40px;
               line-height: 40px;
               display: inline-block;
               width: 40px;
               margin-right: 10px;
               font-size: 12px;
               font-weight: 500;
               text-align: center;
            }
         }
         div.amount {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 40px;
            padding-right: 50px;
            div.unit {
               display: inline-block;
               width: 70px;
               height: 40px;
               margin-left: 8px;
               text-align: center;
               font-size: 12px;
               font-weight: 500;
               line-height: 40px;
            }
            div.all {
               display: inline-block;
               width: 100px;
               height: 40px;
               margin-left: 8px;
               text-align: center;
               font-size: 12px;
               font-weight: 500;
               line-height: 40px;
            }
         }
      }
      div.stocks {
         padding-left: 76px;
         ul {
            font-size: 0;
            &.one-size {
               li {
                  width: 100px;
               }
            }
            li {
               height: 40px;
               line-height: 40px;
               display: inline-block;
               width: 40px;
               margin-right: 10px;
               font-size: 12px;
               font-weight: 500;
               text-align: center;
               color: $black-300;
            }
         }
      }
      div.values {
         div.line {
            position: relative;
            height: 40px;
            margin-bottom: 20px;
            padding-left: 76px;
            div.currentColor {
               position: absolute;
               top: 0px;
               left: 0px;
               width: 70px;
               span {
                  @include circle(34px);
                  display: block;
                  margin-left: 24px;
                  margin-top: 3px;
               }
            }
            ul {
               font-size: 0;
               &.one-size {
                  li {
                     width: 100px;
                  }
               }
               li {
                  height: 40px;
                  line-height: 40px;
                  display: inline-block;
                  width: 40px;
                  margin-right: 10px;
                  font-size: 12px;
                  font-weight: 500;
                  text-align: center;
                  list-style-type: none;
                  input {
                     @include border-radius(4px);
                     width: 100%;
                     height: 40px;
                     border: solid 1px #e6e9e8;
                     text-align: center;
                     &:focus {
                        outline: none;
                        box-shadow: none;
                     }
                  }
               }
            }
            div.delete {
               position: absolute;
               top: 0px;
               right: 0px;
               height: 40px;
            }
            div.amount {
               position: absolute;
               top: 0px;
               right: 0px;
               height: 40px;
               padding-right: 50px;
               div.unit {
                  @include border-radius(4px);
                  display: inline-block;
                  width: 70px;
                  height: 40px;
                  background-color: $black-100;
                  margin-left: 8px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 40px;
               }
               div.all {
                  @include border-radius(4px);
                  display: inline-block;
                  width: 100px;
                  height: 40px;
                  background-color: $black-100;
                  margin-left: 8px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 40px;
               }
            }
         }
      }
   }
   div.moduleCustom {
      padding-bottom: 50px;
      display: none;
      &.visible {
         display: block;
      }
      .name {
         text-transform: uppercase;
         font-size: 12px;
         font-weight: bold;
         margin-bottom: 20px;
      }
		div.not-available {
			margin-top: 10px;
			display: none;
			font-size: 14px;
			&.active {
				display: block;
			}
		}
		div.content {
			margin-bottom: 20px;
			p {
				color: $black-300;
		      font-size: 14px;
		      font-weight: normal;
		      margin-bottom: 0px;
			}
			ul.root {
				li.item {
					position: relative;
					font-size: 14px;
					font-weight: bold;
					padding-left: 0px;
					margin-bottom: 18px;
					padding-top: 18px;
					padding-bottom: 18px;
					margin-top: 22px;
					div.description {
						ul {
							@include no-mp();
							li {
								@include border-radius(4px);
								display: inline-block;
								position: relative;
								background-color: $black-100;
								font-size: 12px;
								margin-right: 10px;
								margin-bottom: 10px;
								line-height: 26px;
								padding-left: 8px;
								padding-right: 8px;
							}
						}
					}
				}
			}
		}
   }
   div.summary {
      @include border-radius(4px);
      background-color: $black-100;
      padding: 30px;
      margin-bottom: 30px;
      ul {
         text-align: right;
         margin-bottom: 8px;
         li {
            display: inline-block;
         }
         li.label {
            color: $black-300;
            font-size: 12px;
            font-weight: bold;
         }
         li.value {
            color: $black-300;
            font-size: 12px;
            width: 110px;
         }
         &.amount {
            margin-top: 20px;
				&+ul {
					margin-top: -10px;
				}
            li {
               font-size: 14px;
               font-weight: 900;
               color: black;
               text-transform: uppercase;
            }
         }
      }
   }
   div.actions {
      text-align: right;
      a {
         margin-left: 20px;
      }
   }
}

.configurator__sizes {
   padding-bottom: 50px;
   @include media(xs) {
      padding-bottom: 120px;
   }
   &.active {
      display: block;
   }
   .name {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
   }
   div.labels {
      @include border-radius(4px);
      background-color: $black-100;
      height: 40px;
      padding-left: 76px;
      position: relative;
      @include media(xs) {
         display: none;
      }
      ul {
         font-size: 0;
         &.one-size {
            li {
               width: 100px;
            }
         }
         li {
            height: 40px;
            line-height: 40px;
            display: inline-block;
            width: 40px;
            margin-right: 10px;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
         }
      }
      div.amount {
         position: absolute;
         top: 0px;
         right: 0px;
         height: 40px;
         padding-right: 50px;
         div.unit {
            display: inline-block;
            width: 70px;
            height: 40px;
            margin-left: 8px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 40px;
         }
         div.all {
            display: inline-block;
            width: 100px;
            height: 40px;
            margin-left: 8px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 40px;
         }
      }
   }
   div.stocks {
      padding-left: 76px;
      ul {
         font-size: 0;
         &.one-size {
            li {
               width: 100px;
            }
         }
         li {
            height: 40px;
            line-height: 40px;
            display: inline-block;
            width: 40px;
            margin-right: 10px;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            color: $black-300;
         }
      }
   }
   div.values {
      margin-top: 12px;
      div.line {
         position: relative;
         height: 40px;
         margin-bottom: 20px;
         padding-left: 76px;
         @include media(xs) {
            padding-left: 0px;
            height: auto;
         }
         div.currentColor {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 70px;
            @include media(xs) {
               position: relative;
               width: 100%;
            }
            span {
               @include circle(34px);
               display: block;
               margin-left: 24px;
               margin-top: 3px;
               @include media(xs) {
                  @include circle(18px);
                  margin-left: 12px;
               }
            }
         }
         ul {
            font-size: 0;
            @include media(xs) {
               display: block;
               width: 100%;
               margin-bottom: 12px;
            }
            &.one-size {
               li {
                  width: 100px;
               }
            }
            li {
               height: 40px;
               line-height: 40px;
               display: inline-block;
               width: 40px;
               margin-right: 10px;
               font-size: 12px;
               font-weight: 500;
               text-align: center;
               list-style-type: none;
               @include media(xs) {
                  width: 100%;
                  text-align: left;
                  margin-bottom: 6px;
               }
               .name {
                  display: none;
                  @include media(xs) {
                     width: 40%;
                     display: inline-block;
                  }
               }
               input {
                  @include border-radius(4px);
                  width: 100%;
                  height: 40px;
                  border: solid 1px #e6e9e8;
                  text-align: center;
                  @include media(xs) {
                     width: auto;
                     display: inline-block;
                  }
                  &:focus {
                     outline: none;
                     box-shadow: none;
                  }
               }
            }
         }
         div.delete {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 40px;
            .name {
               display: none;
               @include media(xs) {
                  display: inline-block;
               }
            }
            @include media(xs) {
               text-align: center;
               position: relative;
               top: 0px;
               right: 0px;
            }
            a {
               color:inherit;
            }
         }
         div.amount {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 40px;
            padding-right: 50px;
            @include media(xs) {
               position: relative;
               padding-right: 0px;
            }
            div.unit {
               @include border-radius(4px);
               display: inline-block;
               width: 70px;
               height: 40px;
               background-color: $black-100;
               margin-left: 8px;
               text-align: center;
               font-size: 14px;
               font-weight: 500;
               line-height: 40px;
               @include media(xs) {
                  width: 100%;
                  margin-bottom: 6px;
               }
               .name {
                  display: none;
                  @include media(xs) {
                     display: inline-block;
                  }
               }
            }
            div.all {
               @include border-radius(4px);
               display: inline-block;
               width: 100px;
               height: 40px;
               background-color: $black-100;
               margin-left: 8px;
               text-align: center;
               font-size: 14px;
               font-weight: 500;
               line-height: 40px;
               @include media(xs) {
                  width: 100%;
                  margin-bottom: 6px;
               }
               .name {
                  display: none;
                  @include media(xs) {
                     display: inline-block;
                  }
               }
            }
         }
      }
   }
}

.configurator__colors {
   padding-bottom: 40px;
   .name {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
   }
   .colors {
      li {
         @include circle(30px);
         display: inline-block;
         margin-right: 6px;
         border: 1px solid #ced4da;
         padding: 4px;
         margin-bottom: 4px;
         div {
            @include circle(20px);
            overflow: hidden;
         }
         &.multiple {
            span {
               width: 10px;
               height: 20px;
               display: inline-block;
            }
         }
         &.single {
            span {
               @include cube(20px);
               display: inline-block;
            }
         }
      }
   }
}

.configurator__custom {
   margin-bottom: 30px;
   .name {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
   }
   .content {
      margin-bottom: 20px;
      p {
         color: $black-300;
         font-size: 12px;
         font-weight: normal;
         margin-bottom: 0px;
      }
      ul {
         margin-top: 16px;
         li {
            @include border-radius(4px);
            background-color: $black-100;
            padding: 18px;
            position: relative;
            .name {
               margin-bottom: 0px;
            }
            .priceCustom {
               color: $black-300;
               font-size: 12px;
            }
            .prices {
               margin-bottom: 0;
               font-size: 12px;
               font-weight: 500;
               position: absolute;
               top: 32px;
               right: 90px;
               text-align: right;
            }
            .options {
               ul {
                  @include no-mp();
                  @include media(xs) {
                     max-width: 50%;
                  }
                  li {
                     @include border-radius(4px);
                     display: inline-block;
                     margin-right: 8px;
                     padding: 4px 6px;
                     font-size: 10px;
                     font-weight: 500;
                     color: white;
                     background-color: black;
                  }
               }
            }
            .actions {
               position: absolute;
               top: 32px;
               right: 24px;
               button {
                  cursor: pointer;
                  background: none;
                  border: 0px;
                  font-size: 14px;
               }
            }
         }
      }
   }
   .btn-default {
      background-color: #f7f9fb;
      color: black;
      border: none;
      font-weight: 700;
      width: 100%;
   }
}

.configurator__sum {
   text-align: right;
   ul {
      text-align: right;
      margin-bottom: 8px;
      li {
         display: inline-block;
      }
      li.label {
         color: $black-300;
         font-size: 12px;
         font-weight: bold;
      }
      li.value {
         color: $black-300;
         font-size: 12px;
         width: 110px;
      }
      &.amount {
         margin-top: 20px;
         &+ul {
            margin-top: -10px;
         }
         li {
            font-size: 14px;
            font-weight: 900;
            color: black;
            text-transform: uppercase;
         }
      }
   }
   button.btn {
      margin-top: 24px;
   }
}

.modal__configurator {
   .modal-content {
      border: none;
   }
   .modal-header {
      border: none;
   }
   .modal-footer {
      border: none;
   }
}

.modal__configurator__custom {
   .label {
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 700;
      button {
         padding: 5px!important;
         height: auto;
         line-height: 12px;
         border: none;
         font-size: 14px;
         &:focus {
            outline: none;
            border: none;
            box-shadow: none;
         }
      }
   }
   .options {
      ul {
         li {
            @include border-radius(6px);
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            border: 1px solid $black-200;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            margin-bottom: 6px;
            .name {
               .advise {
                  display: inline-block;
                  font-size: 9px;
                  @include border-radius(4px);
                  background-color: #8fb063;
                  color: white;
                  padding-left: 4px;
                  padding-right: 4px;
                  position: relative;
                  left: 1px;
                  top: -1px;
               }
            }
            .description {
               font-size: 11px;
               color: $black-300;
            }
            &.active {
               background-color: black;
               border: 1px solid black;
               color: white;
               .name {
                  .advise {
                     background-color: white;
                     color: black;
                  }
               }
               .description {
                  color: white;
               }
            }
            &.blocked {
               opacity: 0.4;
            }
            &.disabled {
               display: none;
            }
         }
      }
      &.locations, &.uniq, &.canvas, &.colors {
         ul {
            li {
               display: inline-block;
               width: auto;
               margin-right: 6px;
            }
         }
      }
   }
}
