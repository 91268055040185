div.modal-select-payment {
   .close {
      color: black;
      opacity: 1;
   }
   div.modal-dialog {
      max-width: 520px;
   }
   div.modal-content {
      @include border-radius(3px);
      border: none;
      box-shadow: none;
      div.sidebar {
         width: 342px;
         height: 100%;
         position: absolute;
         top: 0px;
         right: 0px;
      }
   }
   div.modal-footer {
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
      padding-top: 18px;
      display: block!important;
      border-top: 1px solid #eff1f6;
      .btn-primary {
         float: left;
      }
      .btn-default {
         float: right;
      }
   }
   div.modal-header {
      padding-left: 20px;
      padding-right: 20px;
      height: 65px;
      line-height: 65px;
      border-bottom: 1px solid #eff1f6;
      h5 {
         font-size: 12px;
         font-weight: bold;
         line-height: 32px;
      }
   }
   div.modal-body {
      @include no-mp();
      position: relative;
      padding: 30px;
      div.sidebar {
         background-color: #f7f9fb;
         padding: 36px;
         div.wrapper {
            position: relative;
            height: 100%;
            div.summary {
               position: absolute;
               left: 0px;
               bottom: 0px;
               width: 100%;
					button {
						margin-top: 20px;
						width: 100%;
					}
               div.amount {
                  position: relative;
                  height: 24px;
                  line-height: 24px;
                  margin-bottom: 5px;
                  div.field {
                     font-size: 16px;
                     font-weight: bold;
                  }
                  div.value {
                     position: absolute;
                     top: 0px;
                     right: 0px;
                     font-size: 16px;
                     font-weight: bold;
                  }
               }
               div.amount_by_unit {
                  position: relative;
                  height: 15px;
                  line-height: 15px;
                  color: #868e96;
                  div.field {
                     font-size: 10px;
                     font-weight: bold;
                  }
                  div.value {
                     position: absolute;
                     top: 0px;
                     right: 0px;
                     font-size: 10px;
                     font-weight: bold;
                  }
               }
            }
            ul {
               li {
                  margin-bottom: 10px;
                  div.title {
                     position: relative;
                     font-size: 16px;
                     font-weight: bold;
                     margin-bottom: 10px;
                     line-height: 24px;
                     div.links {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        line-height: 24px;
                        height: 24px;
                        text-align: right;
                        font-size: 10px;
                        font-weight: 500;
                        a {
                           font-size: 10px;
                           font-weight: 500;
                           text-decoration: underline;
                           color: black;
                           &:hover {
                              color: black;
                           }
                        }
                     }
                  }
                  div.description {
                     padding-bottom: 15px;
                     border-bottom: solid 1px #eff1f6;
                     ul {
                        li {
                           font-size: 10px;
                           line-height: 14px;
                           margin-bottom: 3px;
                           color: #868e96;
                           b {
                              font-weight: bold;
                           }
                        }
                     }
                  }
               }
            }
         }
      }
      div.content {
         padding: 50px;
         div.option {
            margin-bottom: 34px;
				&.option-unique {
					div.label {
						display: inline-block;
					}
					div.info {
						display: inline-block;
						position: relative;
						left: 4px;
						i {
							position: relative;
							font-size: 14px;
							top: 1px;
						}
						div.content {
							display: none;
						}
					}
				}
            &.advanced {
               ul {
                  li {
                     height: 82px;
                     width: 30%;
                     padding: 24px;
                     margin-bottom: 12px;
                     opacity: 1;
                     @include transition(0.6);
							position: relative;
                     &.disabled {
                        opacity: 0.6;
                        div.title {
                           text-decoration: line-through;
                        }
                        /*
                        border: solid 1px #dbdbdb;
                        background-color: #f5f5f5;
                        position: relative;
                        div.title {
                           text-decoration: line-through;
                        }
                        div.description {
                           text-transform: uppercase;
                           font-size: 8px;
                           font-weight: 900;
                           line-height: 16px;
                           position: relative;
                           height: 16px;
                        }
                        */
                     }
                     div.title {
                        @include no-mp();
                        font-size: 12px;
                        font-weight: bold;
                        color: black;
                        line-height: 12px;
                        margin-bottom: 12px;
                     }
                     div.description {
                        @include no-mp();
                        font-size: 12px;
                        font-weight: 500;
                        color: #a6a6a6;
                        line-height: 16px;
                        position: relative;
                        height: 16px;
                     }
							div.info {
								position: absolute;
								top: 10px;
								right: 10px;
								i {
									position: relative;
									left: -6px;
								}
								div.content {
									display: none;
								}
							}
                  }
               }
            }
            div.label {
               font-size: 12px;
               font-weight: bold;
               margin-bottom: 15px;
            }
            ul {
               li {
                  @include border-radius(4px);
                  cursor: pointer;
                  list-style: none;
                  display: inline-block;
                  padding-left: 20px;
                  padding-right: 20px;
                  height: 42px;
                  font-size: 12px;
                  font-weight: 500;
                  color: black;
                  border: solid 1px #f1f3f7;
                  background-color: #f7f9fb;
                  line-height: 42px;
                  margin-right: 10px;
                  &.active {
                     border: solid 2px $brand-500;
                     line-height: 40px;
                  }
               }
            }
         }
      }
   }
}
