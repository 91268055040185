section#print-method {
	padding-top: 100px;
	padding-bottom: 100px;
	header {
		padding-bottom: 40px;
		margin-bottom: 32px;
	}
	section {
		img {
			width: 100%;
		}
		p {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
}
