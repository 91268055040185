div#deleteAccountModal {
	div.modal-dialog {
		border: none;
		max-width: 480px;
		div.modal-content {
			border: none;
			div.modal-header {
				padding: 30px;
				border: none;
			}
			div.modal-body {
				padding: 30px;
				padding-top: 0px;
				button {
					margin-top: 40px;
					width: 100%;
				}
				input {
					width: 100%;
				}
			}
		}
	}
	div.modal-footer {
		padding: 30px;
		border: none;
		margin-top: -30px;
		button, a {
			width: 100%;
		}
	}
}
