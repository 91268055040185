


section#account {
	padding-top: 100px;
	padding-bottom: 200px;
	@include media(xs) {
		padding-top: 30px;
	}
	ul.nav {
		li {
			display: block;
			width: 100%;
			margin-bottom: 10px;
			a {
				color: black;
				font-size: 14px;
				margin-bottom: 10px;
				@include media(xs) {
					font-weight: bold;
				}
			}
		}
	}
	div.wrapper {
		@include border-radius(3px);
		padding: 50px;
		border: 1px solid #dce7ee;
		@include media(xs) {
			padding: 20px;
		}
		header {
			h2 {
				font-size: 24px;
			}
			p {
				font-size: 14px;
			}
		}
		section {
			h3 {
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;
				margin-top: 30px;
			}
			p {
				font-size: 14px;
			}
			button {
				margin-top: 40px;
			}
		}
	}
	.purchases {
		.table {
			tr {
				cursor: pointer;
				&:hover {
					td {
						font-weight: bold;
					}
				}
				th {
					font-size: 10px;
					text-transform: uppercase;
				}
				td {
					font-size: 12px;
				}
			}
		}
	}
	div.products {
		div.item {
			margin-bottom: 50px;
			@include wrapperHeader();
			div.content {
				position: relative;
				padding-left: 120px;
				div.media {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100px;
					height: 130px;
					background-color: $black-100;
					img {
						width: 100%;
					}
				}
				div.quantity {
					div.line {
						position: relative;
						height: 54px;
						padding-top: 14px;
						padding-left: 45px;
						border-bottom: solid 1px #eff1f6;
						img {
							width: 54px;
							position: absolute;
							top: 0px;
							left: -80px;
						}
						div.color {
							@include cube(54px);
							position: absolute;
							top: 0px;
							left: 0px;
							padding-left: 7px;
							padding-top: 18px;
							span {
								@include circle(19px);
								display: block;
							}
						}
						ul.attributes {
							font-size: 0px;
							li {
								@include border-radius(4px);
								height: 26px;
								background-color: $black-100;
								font-size: 10px;
								font-weight: bold;
								line-height: 26px;
								padding-left: 12px;
								padding-right: 12px;
								display: inline-block;
								margin-right: 10px;
							}
						}
						ul.values {
							font-size: 0px;
							position: absolute;
							top: 0px;
							right: 0px;
							height: 26px;
							padding-top: 14px;
							padding-right: 20px;
							li {
								display: inline-block;
								height: 26px;
								width: 70px;
								color: black;
								font-size: 12px;
								font-weight: bold;
								text-align: center;
							}
						}
					}
				}
				div.configuration {
					@include border-radius(4px);
					position: relative;
					background-color: $black-100;
					font-size: 14px;
					font-weight: bold;
					padding-left: 20px;
					margin-bottom: 18px;
					padding-top: 18px;
					padding-bottom: 18px;
					margin-top: 22px;
					div.main {
						position: relative;
					}
					div.sub {
						position: relative;
						margin-top: 10px;
						margin-bottom: 10px;
						ul {
							li {
								line-height: 20px;
							}
						}
					}
					b {
						@include no-mp();
						font-size: 12px;
						margin-bottom: 2px;
					}
					p {
						@include no-mp();
						font-size: 12px;
						font-weight: 500;
					}
					.features {
						font-size: 12px;
						ul {
							li {
							}
						}
					}
					.line {
						font-size: 12px;
					}
					ul.values {
						position: absolute;
						top: 0px;
						right: 0px;
						height: 100%;
						padding-right: 20px;
						font-size: 0px;
						li {
							display: inline-block;
							height: 100%;
							line-height: 40px;
							width: 70px;
							font-size: 12px;
							font-weight: bold;
							text-align: center;
						}
					}
					div.selector {
						position: absolute;
						top: 20px;
						right: 20px;
						ul {
							@include border-radius(3px);
							background-color: white;
							padding: 5px;
							li {
								@include border-radius(3px);
								display: inline-block;
								font-size: 10px;
								padding-left: 15px;
								padding-right: 15px;
								height: 24px;
								line-height: 24px;
								cursor: pointer;
								&.active {
									background-color: black;
									color: white;
								}
							}
						}
					}
				}
				div.upload {
					input[type="file"] {
						 display: none;
					}
					.customFile, .uniqueFile {
						 @include no-mp();
						 @include border-radius(3px);
						 border: 1px solid black;
						 display: inline-block;
						 padding-left: 20px;
						 padding-right: 20px;
						 line-height: 40px;
						 height: 40px;
						 cursor: pointer;
						 background-color: black;
						 font-size: 10px;
						 font-weight: 500;
						 text-transform: uppercase;
						 letter-spacing: 0.6px;
						 color: white;
						 margin-bottom: 10px;
						 i {
							 margin-right: 10px;
						 }
						 a {
							 margin-left: 10px;
							 color: white;
							 i {
								 margin-right: 0px;
							 }
						 }
					}
					div.file {
						display: none;
						&.active {
							display: block;;
						}
					}
					div.text {
						display: none;
						&.active {
							display: block;;
						}
					}
					div.unique {
						div.wrapper {
							background-color: #f7f9fb;
							margin-top: 10px;
							margin-bottom: 10px;
							padding: 18px 20px;
							margin-bottom: 20px;
							div.title {
								font-size: 12px;
								font-weight: bold;
							}
							div.paragraph {
								font-size: 12px;
							}
						}
					}
					textarea {
						min-height: 120px;
					}
					div.currentFile {
						@include border-radius(4px);
						position: relative;
						background-color: $black-100;
						font-size: 14px;
						padding-left: 20px;
						margin-bottom: 18px;
						padding-top: 18px;
						padding-bottom: 18px;
						margin-top: 22px;
						a {
							position: absolute;
							top: 5px;
							right: 10px;
						}
					}
				}
				div.subAmount {
					position: relative;
					font-size: 14px;
					font-weight: bold;
					padding-left: 20px;
					margin-bottom: 20px;
					margin-top: 20px;
					height: 20px;
					b {
						@include no-mp();
						font-size: 14px;
						font-weight: 900;
						line-height: 20px;
					}
					ul.values {
						position: absolute;
						top: 0px;
						right: 0px;
						height: 20px;
						padding-right: 20px;
						font-size: 0px;
						li {
							display: inline-block;
							height: 20px;
							line-height: 20px;
							width: 70px;
							font-size: 14px;
							font-weight: 900;
							text-align: center;
						}
					}
				}
			}
			div.footer {
				padding-top: 20px;
				text-align: right;
				a {
				}
			}
		}
	}
	div.more {
		div.summary {
			@include border-radius(4px);
			background-color: $black-100;
			padding: 30px;
			margin-bottom: 30px;
			ul {
				text-align: right;
				margin-bottom: 8px;
				li {
					display: inline-block;
				}
				li.label {
					color: $black-300;
					font-size: 12px;
					font-weight: bold;
				}
				li.value {
					color: $black-300;
					font-size: 12px;
					width: 110px;
				}
				&:last-child {
					margin-top: 20px;
					li {
						font-size: 14px;
						font-weight: 900;
						color: black;
						text-transform: uppercase;
					}
				}
			}
		}
	}
	.lastest-connections {
		margin-top: 32px;
		padding-top: 32px;
		border-top: 1px solid #f4f5f7;

		table {
			border-collapse: separate;
			border-spacing: 0px 8px;
			width: 100%;

			th {
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
			}

			td {
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;

				&.date {
					text-align: right;
				}
			}
		}
	}

	div#logoutModal {
		.modal-dialog {
			.modal-content {
				.modal-footer {
					button {
						margin-right: 20px;
					}
				}
			}
		}
	}
}
