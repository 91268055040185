$brand-500: #623bea;

$black-300: #868e96;
$black-200: #dce7ee;
$black-100: #f7f9fb;


$danger-500: #e54b4b;
$danger-600: #b81b3e;
$info-500: #19a3c4;
$info-600: #1280a8;
$warning-500: #ff9900;
$warning-600: #db7a00;
$success-200: #c9e4db;
$success-500: #4ea88a;